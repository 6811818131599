import axios from 'axios';
import store from '@/store';
import Cookies from 'js-cookie';

const getToken = () => {
    return Cookies.get('token') ? JSON.parse(Cookies.get('token')) : null;
};

const handleExpiredToken = async () => {
    console.log('handleExpiredToken');
    await store.dispatch('session/logout');

    store.dispatch('ui/setError', {
        message: 'Tu sesión ha expirado',
    });

};

const handleErrorsStatus = async (status) => {
    switch (status) {
        case 401:
            return handleExpiredToken();
        default:
            return status;
    }
};

const handleErrors = (error) => {
    if (error.response?.data) {
        const { data } = error.response;

        store.dispatch('ui/setError', {
            code: error.code,
            message: data.message ? data.message : data.error,
        });
    }

    return handleErrorsStatus(error.response.status);
};

export async function axiosData(
    headers = false,
    path,
    jsonData,
    method,
    blob = false,
    hasCancel = false,
    getAllData = false
) {
    const endpoint = `${process.env.VUE_APP_API}${path}`;

    const urlsWithoutToken = [];

    const urlHasAuthOff = urlsWithoutToken.find((elm) => elm === path);

    let headerToken =
        headers && !urlHasAuthOff
            ? { Authorization: `Bearer ${getToken()?.tokenId}` }
            : {};

    try {
        let cancel;
        if (hasCancel) {
            const { CancelToken } = axios;
            const source = CancelToken.source();
            cancel = source.cancel;
        }

        const response = await axios({
            headers: headerToken,
            url: endpoint,
            data: jsonData,
            responseType: blob ? 'blob' : 'json',
            method: method,
            cancelToken: cancel,
        });

        return getAllData ? response : response.data;
    } catch (error) {
        if (error.response) return handleErrors(error);
    }
}
