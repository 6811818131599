import Vue from "vue";
import Vuex from "vuex";

import sessionModule from './store/modules/session';
import uiModule from './store/modules/ui';
import clientesModule from './store/modules/clientes';
import generalModule from './store/modules/general';
import libreriasModule from './store/modules/librerias';
import eventosModule from './store/modules/eventos';
import juegosModule from './store/modules/juegos';
import fiestasModule from './store/modules/fiestas';
import membresiasModule from './store/modules/membresias';
import listasModule from './store/modules/listas';
import ticketsModule from './store/modules/tickets';
import zonasModule from './store/modules/zonas';
import contactosModule from './store/modules/contactos';
import tipocarnetsModule from './store/modules/tipocarnets';
import tipolistasModule from './store/modules/tipolistas';
import reservasModule from './store/modules/reservas';
import espaciosModule from './store/modules/espacios';
import personasModule from './store/modules/personas';
import diseniosModule from './store/modules/disenios';
import puertasModule from './store/modules/puertas';
import actosModule from './store/modules/actos';
import inscripcionesModule from './store/modules/inscripciones';
Vue.use(Vuex);
const getDefaultState = () => {
    return {
        isMobile: false,
        routes: null,
        routesObject: null,
        showSpinner: false,
        showLoginbox:false,
        showInscribete: false,
    }
}
const state = getDefaultState()

export default new Vuex.Store({
    state,
  getters: {
  },
  mutations: {
      updateResolution(state, payload) {
          state.isMobile = payload;
      },
  },
  actions: {
      updateResolution: ({ commit }, payload) =>
          commit('updateResolution', payload),
  },
    plugins:[],
    modules:{
        session:sessionModule,
        ui: uiModule,
        clientes: clientesModule,
        librerias: libreriasModule,
        eventos: eventosModule,
        general: generalModule,
        juegos: juegosModule,
        fiestas: fiestasModule,
        membresias: membresiasModule,
        listas: listasModule,
        tickets: ticketsModule,
        zonas: zonasModule,
        contactos: contactosModule,
        tipocarnets : tipocarnetsModule,
        tipolistas : tipolistasModule,
        reservas : reservasModule,
        espacios : espaciosModule,
        personas: personasModule,
        disenios: diseniosModule,
        puertas: puertasModule,
        actos : actosModule,
        inscripciones: inscripcionesModule
    }
});
