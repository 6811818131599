// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const configOptions = {
  apiKey: "AIzaSyCUezrakfG6_6Nv5NK68wtwcSP2zsnKyVg",
  authDomain: "sodoma-b1cd2.firebaseapp.com",
  projectId: "sodoma-b1cd2",
  storageBucket: "sodoma-b1cd2.appspot.com",
  messagingSenderId: "366075933693",
  appId: "1:366075933693:web:61d65a1a367142a8069f94",
  measurementId: "G-3JLED1MVXT"
};

firebase.initializeApp(configOptions);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.analytics();

export default firebase;
